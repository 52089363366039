import type { ContentPaper } from '~/typesManual/content_api/paper'

interface TrialModalState {
  open: boolean
  papers?: ContentPaper[]
  requestedProducts?: string[] // Used for MM only, define the requested product for the marketing (will be sent in letter to marketing)
  skipStartTrial?: boolean
  returnUrl?: string
}

export const useTrialModalState = () => {
  return useState<TrialModalState>('trialModalState', () => ({
    open: false,
  }))
}
